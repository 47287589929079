var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _vm._l(_vm.sections, function(section) {
        return _c("div", { key: section.title, staticClass: "settingsPage" }, [
          _c("h4", { staticClass: "mb-0 font-size-18" }, [
            _vm._v(_vm._s(section.title))
          ]),
          _vm._v(" "),
          _c("br"),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "col-lg-1" }),
              _vm._l(section.settings, function(settingSection) {
                return _c(
                  "div",
                  { key: settingSection.title, staticClass: "col-lg-2" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: settingSection.route } } },
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "border border-primary text-center",
                            attrs: {
                              "header-class": "bg-transparent border-primary"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function() {
                                    return [
                                      _c(
                                        "h5",
                                        { staticClass: "my-0 text-primary" },
                                        [
                                          _c("i", {
                                            staticClass: "settingIcon",
                                            class: settingSection.icon
                                          })
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("a", [_vm._v(_vm._s(settingSection.title))])]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c("div", { staticClass: "col-lg-1" })
            ],
            2
          ),
          _c("hr")
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }